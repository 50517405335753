import {
  Grid,
  Flex,
  Box,
  SimpleGrid,
  Button,
  Alert,
  AlertIcon,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import FeaturedPostCard from "../FeaturedPostCard";
import PostCard from "../PostCard";

let BLOG_PAGE_SIZE = 20;



export type CollectionProps = {
  pagingType?: "Infinite" | "Numeric" | "Alphabetical" | string | undefined;
  collection?: any[];
  maxItemsToDisplay?: number;
  contentType?: "Blog Posts" | "Team Member" | string | undefined;
  themeName?:
    | "White Card with Shadow"
    | "Horizontal White Card with Shadow"
    | string
    | undefined;
};

const getPostCard = (item: any, i: number) => {
  if (item?.fields?.content?.fields) {
    return (
      <PostCard
        internalName={item.internalName || item.name}
        blogPostPage={item}
        key={i}
      />
    );
    return null;
  } else {
    return <PostCard post={item} key={i} variant="variant1" />;
  }
};

const getFeaturedPost = (item: any, i: number) => {
  if (item?.fields?.content?.fields) {
    return (
      <FeaturedPostCard
        internalName={item.internalName || item.name}
        featuredBlogPost={item}
        key={i}
      />
    );
  } else {
    return <FeaturedPostCard post={item.post || item} key={i} />;
  }
};
const transformPosts = (
  collection: any,
  maxItemsToDisplay: number,
  themeName?: string
) => {
  if (collection && collection.length > 0) {
    const c = JSON.parse(JSON.stringify(collection));
    const d = c?.splice(0, maxItemsToDisplay);
    const mi = d?.map((item: any, i: number) =>
      themeName && themeName === "Horizontal White Card with Shadow"
        ? getFeaturedPost(item, i)
        : getPostCard(item, i)
    );
    if (mi && mi.length > 0) {
      return mi;
    }
    return null;
  }
};

const Collection = (data: CollectionProps): JSX.Element => {
  const { pagingType, collection, contentType, maxItemsToDisplay, themeName } =
    data;
  const [isLoading, setIsLoading] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [maxDisplay, setMaxDisplay] = useState<number>(maxItemsToDisplay || 9);

  const mapped = transformPosts(collection, maxDisplay, themeName);
  const [mappedItems, setMappedItems] = useState<JSX.Element[] | undefined>(
    mapped
  );
  const [offset, setOffset] = useState(BLOG_PAGE_SIZE);

  useEffect(() => {
    console.log("useEffect");
    if (collection && collection.length > 0) {
      const mapped = transformPosts(collection, maxDisplay, themeName);
      setMappedItems(mapped);
    }
  }, [collection, maxDisplay, themeName]);

  const hitMe = async () => {
    try {
      setIsLoading(true);
      setShowErrorMessage(false);

      const { data } = await axios.get(
        `/api/posts?skip=${offset}&limit=${BLOG_PAGE_SIZE}&order=-sys.createdAt`
      );

      const transformedPosts = transformPosts(data, maxDisplay, themeName);
      if (mappedItems && transformedPosts) {
        const newMap = [...mappedItems, ...transformedPosts];
        setMappedItems(newMap);
        setOffset(offset + BLOG_PAGE_SIZE);
      }
    } catch (err) {
      // show error dialog
      console.error(err);
      setShowErrorMessage(true);
    } finally {
      setIsLoading(false);
    }
  };

  const errorMessage = showErrorMessage ? (
    <Alert status="error">
      <AlertIcon />
      There was an error processing your request
    </Alert>
  ) : (
    <span />
  );

  const getColumns = () => {
    switch (maxDisplay) {
      case 1:
        return 1;
      case 2:
        return [1, 1, 2];
      default:
        return [1, 1, 2, 3];
    }
  };

  return mappedItems && mappedItems.length > 0 ? (
    <Box pt={4}>
      <SimpleGrid gap={16} columns={getColumns()} mb={16}>
        {mappedItems}
      </SimpleGrid>
      {pagingType === "Infinite Grid" ? (
        <Box p={4}>
          {errorMessage}
          <Button
            variant="brand-solid-accent"
            aria-label="Click here to load more articles below"
            isLoading={isLoading}
            loadingText="loading..."
            onClick={hitMe}
          >
            More Articles
          </Button>
        </Box>
      ) : (
        <span />
      )}
    </Box>
  ) : (
    <Box>No Articles Found</Box>
  );
};

/*
(
      <PostCard
        post={item}
        key={`${i}-${maxItemsToDisplay}`}
        variant="variant1"
      />
    );
*/
export default React.memo(Collection);
