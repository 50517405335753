import React, { useEffect } from "react";
import PostCardOld from "./index.old";
import PostCard from "./PostCard";

const PostCardSwitcher = (params: any) => {
  const [post, setPost] = React.useState(params.post);
  const [key, setKey] = React.useState(params.key);
  const [blogPostPage, setBlogPostPage] = React.useState(params.blogPostPage);
  useEffect(() => {
    setPost(params.post);
    setKey(params.key);
    setBlogPostPage(params.blogPostPage);
  }, [params.post, params.key, params.blogPostPage]);

  return params.post ? (
    <PostCardOld post={params.post} key={params.key} />
  ) : (
    <PostCard blogPostPage={params.blogPostPage} key={params.key} />
  );
};

export default PostCardSwitcher;
