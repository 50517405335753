import React, { useEffect } from "react";
import Image from "next/image";
import NextLink from "next/link";
import { Box, Heading, Link } from "@chakra-ui/react";

import {
  Wrapper,
  CoverImageWrapper,
  Body,
  Title,
  Excerpt,
  PostDate,
  ReadMoreButton,
} from "./styles";
import LinkComponent from "src/components/atoms/Link";
import {
  IPage,
  ISeoFields,
  IPageBlogPostFields,
  IPageFields,
} from "src/types/generated/contentful";
import { Asset, AssetFields } from "contentful";
import { fixImagePath } from "src/utils/dom";
import { getExcerptOrSeoDescription } from "../FeaturedPostCard/FeaturedPostCard";

const postDateTemplate = {
  year: "numeric",
  month: "short",
  day: "numeric",
} as const;

export type PostCardProps = {
  blogPostPage: IPage;
  key: number;
  variant?: "variant1" | "variant2";
};

const PostCard: React.FC<PostCardProps> = ({
  blogPostPage,
  key,
  variant = "variant1",
  ...rest
}) => {
//  console.log("blogPostPage", JSON.stringify(blogPostPage), key, variant, rest);
  const pageIn = blogPostPage?.fields as IPageFields;
  const seoIn = pageIn?.seo?.fields as ISeoFields;
  const pageBlogPostIn = pageIn?.content?.fields as IPageBlogPostFields;
  const coverImageInf = pageBlogPostIn?.coverImage?.fields as AssetFields;

  const [page, setPage] = React.useState<IPageFields>(pageIn);
  const [seo, setSeo] = React.useState<ISeoFields>(seoIn);
  const [pageBlogPost, setPageBlogPost] =
    React.useState<IPageBlogPostFields>(pageBlogPostIn);
  const [coverImage, setCoverImage] =
    React.useState<AssetFields>(coverImageInf);

  useEffect(() => {
    setPage(pageIn);
    setSeo(seoIn);
    setPageBlogPost(pageBlogPostIn);
    setCoverImage(coverImageInf);
  }, [pageIn, seoIn, pageBlogPostIn, coverImageInf]);

  const dateTime = page?.pubDate
    ? new Date(page?.pubDate).toLocaleDateString("en-US", postDateTemplate)
    : "Date N/A";

  return (
    <Wrapper $variant={variant} {...rest}>
      {coverImage && coverImage?.file?.url ? (
        <CoverImageWrapper data-testid="CoverImageWrapper">
          <Image
            src={`${fixImagePath(coverImage?.file?.url)}`}
            alt={coverImage?.file?.fileName}
            layout="fill"
            objectFit="cover"
          />
        </CoverImageWrapper>
      ) : null}

      <Body>
        <Heading as="h4" size="lg">
          <Link
            href={`/blog/${page?.slug}`}
            as={NextLink}
            passHref
            color="brandPrimary.500"
          >
            {page?.title}
          </Link>
        </Heading>
        <Box color="brandAccent.600">
          <PostDate dateTime={dateTime}>{dateTime}</PostDate>
        </Box>
        <Excerpt>{getExcerptOrSeoDescription(pageBlogPost, seo)}</Excerpt>
        <Link
          href={`/blog/${page.slug}`}
          as={NextLink}
          aria-label={`Read more on ${page.title}`}
          variant="brand-solid-accent"
        >
          Read more
        </Link>
      </Body>
    </Wrapper>
  );
};

export default React.memo(PostCard);
