import styled, { css } from "styled-components";
import { down } from "styled-breakpoints";
import Typography from "src/components/atoms/Typography";
import Button from "src/components/atoms/Button";

export const CoverImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 296px;
  border-radius: 19px;
  overflow: hidden;
`;

export const Body = styled.div`
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1;
  padding-top: 28px;
`;

export const Title = styled(Typography).attrs({
  variant: "h4",
  component: "a",
})`
  text-decoration: none;
  font-size: 26px;
  font-weight: bold;
  line-height: 28px;
  letter-spacing: 0.24px;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
`;

export const PostDate = styled.time`
  display: inline-block;
  margin-top: 10px;
  font-size: 14px;
  font-weight: 600;
  font-style: italic;
  line-height: 16px;

  ${down("sm")} {
    font-family: ${({ theme }) => theme.typography.fontFamilies.secondary};
    font-weight: bold;
    font-style: normal;
  }
`;

export const Excerpt = styled(Typography)`
  flex: 1;
  margin-top: 18.82px;
  font-size: 15px;
  line-height: 21px;
  font-weight: 500;
`;

export const ReadMoreButton = styled(Button).attrs({ as: "a", size: "sm" })`
  margin-top: 30px;

  ${down("sm")} {
    margin-top: 35px;
    font-size: 14px;
    width: 100%;
    padding: 0.8em;
  }
`;

type WrapperProps = {
  $variant: "variant1" | "variant2";
};
export const Wrapper = styled.div<WrapperProps>`
  display: flex;
  flex-direction: column;

  ${down("sm")} {
    ${({ theme, $variant }) =>
      $variant === "variant1" &&
      css`
        box-shadow: 0 0 25px -3px rgba(0, 0, 0, 0.15);
        background: ${theme.palette.common.white};
        border-radius: 15px;
        overflow: hidden;

        ${CoverImageWrapper} {
          border-radius: 0;
          height: 131px;
        }

        ${Body} {
          padding: 20px 12.5px 26px;
        }

        ${Title} {
          font-size: 21px;
          line-height: 21px;
          letter-spacing: normal;
        }

        ${Excerpt} {
          display: none;
        }
      `}

    ${({ $variant }) =>
      $variant === "variant2" &&
      css`
        ${CoverImageWrapper} {
          border-radius: 13px;
          height: 216px;
        }

        ${Body} {
          padding-top: 26.38px;
        }

        ${Title} {
          font-size: 24px;
          line-height: 28px;
          letter-spacing: 0.23px;
        }

        ${PostDate} {
          margin-top: 5px;
          line-height: 22px;
        }

        ${Excerpt} {
          margin-top: 5px;
          font-size: 16px;
          line-height: 24px;
        }
      `}
  }
`;
